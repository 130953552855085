import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/GH_Big.jpg"
import GH from "../../../images/GH_Square.jpg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Greatest Hits"
    SecondText="Check out sermons from our Greatest Hits sermon series"
  >
    <SEO title="Sermons - Greatest Hits" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="The Ultimate Cleanse"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/ultimate-cleanse"
        date="September 17th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="It's Nice to be Known"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/fully-known"
        date="September 10th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="A to Z Guide for Life"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/guide-for-life"
        date="September 3rd, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="The Benefits Package"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/the-benefits-package"
        date="August 27th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="Cost of a Fresh Start"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/cost-of-a-fresh-start"
        date="August 20th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="Blessings of the Church"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/blessings-of-the-church"
        date="August 13th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="The God of War"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/god-of-war"
        date="August 6th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="When it is Not Well"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/it-is-not-well"
        date="July 30th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="A Hymn of Praise"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/a-hymn-of-praise"
        date="July 23rd, 2023"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={GH}
        imageAlt="Greatest Hits Sermon Series"
        sermonTitle="The Anthem of Life"
        sermonSeries="Greatest Hits"
        sermonLink="/phoenix/sermons/the-anthem-of-life"
        date="July 16th, 2023"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/phoenix/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
